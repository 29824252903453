
import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
import BackButton from '@/views/module/common/BackButton.vue';

import app from '@/app/App';
import User from '@/app/com/main/module/business/user/bean/User';
import PersonalBox from '@/app/com/main/module/business/personal/box/PersonalBox';


@Component({
    components: {
        BackButton,
    },
})
export default class PersonalInfoPane extends Vue {

    private infoBg: string = 'assets/general/wap/images/info/bg1.jpg';
    private user: User = new User();

    public mounted() {
        const pb: PersonalBox = app.appContext.getMaterial(PersonalBox);
        const user: User = pb.getUser();
        this.setUser(user);
    }

    private setUser(user: User) {
        this.user = user;
    }

    public update() {
        this.$router.push({path: '/personal.update.info'});
    }
}
